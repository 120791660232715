.modal-change-password {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5001;
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-change-password.active {
    opacity: 1;
    pointer-events: all;
}

.modal-change-password--content {
    padding: 20px 60px;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 30%;
    text-align: center;
}

.modal-change-password--title {
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 50px;
}

.modal-change-password__button-container {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
}